import {
  FolderOpenOutlined, FolderOutlined,
  SettingOutlined, UserOutlined,
} from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocalization } from '@fluent/react';
import { authStore } from '../../stores/auth';
import { ScreensModal } from '../ScreensModal';
import { TemplatesModal } from '../TemplatesModal';
import { ScreensStore } from '../../stores/screensStore';

type Props = {
  screensStore: ScreensStore;
  wellsOpened: boolean;
  collapsed: boolean;
  toggleWellsOpened: () => void;
};
export const MainMenu = observer(({
  screensStore, wellsOpened, collapsed, toggleWellsOpened,
}
: Props) => {
  const [screensVisible, setScreensVisible] = useState(false);
  const [templatesVisible, setTemplatesVisible] = useState(false);
  const { l10n } = useLocalization();

  const items: Required<MenuProps>['items'] = [
    {
      label: authStore.username,
      icon: <UserOutlined />,
      key: 'user',
      children: [
        { label: authStore.username, key: 'username', type: 'group' },
        { type: 'divider' },
        { label: l10n.getString('Logout'), key: 'user-logout', onClick: () => authStore.logout() },
      ],
    },
    {
      label: l10n.getString('TreeWells'),
      icon: wellsOpened ? <FolderOpenOutlined /> : <FolderOutlined />,
      key: 'wells',
      onClick: () => toggleWellsOpened(),
    },
    {
      label: l10n.getString('Settings'),
      icon: <SettingOutlined />,
      key: 'settings',
      children: [
        {
          label: l10n.getString('WellTemplates'),
          key: 'templates',
          onClick: () => setTemplatesVisible(true),
        },
        {
          label: l10n.getString('ManagerViews'),
          key: 'view-tabs-menu',
          onClick: () => setScreensVisible(true),
        },
      ],
    },
  ];

  return (
    <>
      <Menu items={items} inlineCollapsed={collapsed} mode="inline" selectable={false} />
      <ScreensModal
        screensStore={screensStore}
        visible={screensVisible}
        onClose={() => setScreensVisible(false)}
      />
      <TemplatesModal visible={templatesVisible} onClose={() => setTemplatesVisible(false)} />
    </>
  );
});
