import * as PIXI from 'pixi.js';
import { Container, Sprite } from '@inlet/react-pixi';
import { ReactNode, useContext, useState } from 'react';
import { TabletContext } from './TabletProvider';

type ContainerMaskProps = {
  children: ReactNode;
  width: number;
  height: number;
};

export function ContainerMask({ width, height, children }: ContainerMaskProps) {
  const { themeHex } = useContext(TabletContext);
  const [mask, setMask] = useState<PIXI.Sprite | null>(null);

  return (
    <Container mask={mask}>
      {children}
      <Sprite
        texture={PIXI.Texture.WHITE}
        x={0}
        y={0}
        width={width}
        height={height}
        tint={themeHex.backgroundColor}
        ref={((ref) => {
          setMask(ref);
        })}
      />
    </Container>
  );
}
