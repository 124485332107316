import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Select } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ThemeProvider } from '@emotion/react';
import { FolderData } from '../components/FolderData';
import {
  AppWrapper,
  DataBlockContainer,
  LogoBlock,
  LogoImage,
  ScreensContainer,
  SidebarBottomContainer,
  SidebarContainer,
  SidebarTopContainer,
  TreePanelContainer,
} from '../components/MainLayout';
import { TabletsList } from '../components/TabletsList';
import { WellsSearch } from '../components/WellsSearch';
import { FolderTree } from '../components/FolderTree';
import { MainMenu } from '../components/MainMenu';
import { TrackParams } from '../components/TrackParams';
import { CurveParamsResolver } from '../components/CurveParams/CurveParamsResolver';
import { DragNode } from '../components/FolderTree/DragNode';
import { ThemeSwitcher } from '../components/ThemeSwitcher';
import { ScaleUnitSwitcher } from '../components/ScaleUnitSwitcher';
import { InfoModal } from '../components/InfoModal';
import { OpenWellModal } from '../components/OpenWellModal';
import { stompPublisher } from '../stomp';
import { getWebsocketAddress } from '../utils';
import { ScreensStore } from '../stores/screensStore';
import { localizationStore, themingStore, timeZonesStore } from '../stores/init-stores-global';
import { ScreensTabs } from '../components/ScreenTabs';
import { TabletPreloader } from '../components/Tablet/TabletPreloader';
import { TabletsWrapper } from '../components/TabletsList/TabletList.styled';
import { useFullscreen } from '../hooks';

export const TabletsPage = observer(() => {
  const [screensStore] = useState(new ScreensStore());
  const [params, setSearchParams] = useSearchParams();

  const [infoVisible, setInfoVisible] = useState(false);

  const [well, setWell] = useState<number | null>();

  const [fullscreen, setFullscreen] = useState(false);

  const tabletsRef = useRef<HTMLDivElement>(null);

  const onFullScreen = useCallback(() => setFullscreen(false), []);
  const isFullscreen = useFullscreen(
    tabletsRef,
    fullscreen,
    onFullScreen,
  );

  useEffect(() => {
    if (stompPublisher.connected === false) {
      stompPublisher.init(getWebsocketAddress(process.env.REACT_APP_CACHE_WEBSOCKET));
    }
  }, []);

  useEffect(() => {
    const wellId = params.get('wellId');
    if (wellId) {
      setWell(Number(wellId));
    } else {
      setWell(null);
      if (screensStore.list.length === 0) {
        screensStore.fetchList().then(() => {
          const screenId = params.get('screenId') || screensStore.list[0].screenId;
          screensStore.openScreen(screenId);
          screensStore.loadAll();
        });
      }
    }
  }, [params, screensStore]);

  useEffect(() => {
    if (screensStore.current) {
      setSearchParams({ screenId: screensStore.current });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, screensStore.current, setSearchParams]);
  const [wellsOpened, setWellsOpened] = useState(true);
  const [collapsed] = useState(true);

  return (
    <ThemeProvider theme={themingStore.emotionTheme}>
      <AppWrapper wellsOpened={wellsOpened}>
        <SidebarContainer collapsed={collapsed}>
          <SidebarTopContainer>
            <LogoBlock key="logo">
              <LogoImage
                src={themingStore.current === 'dark'
                  ? process.env.REACT_APP_LOGO_URL_DARK
                  : process.env.REACT_APP_LOGO_URL_LIGHT}
                onClick={() => setInfoVisible(true)}
              />
            </LogoBlock>
            <MainMenu
              screensStore={screensStore}
              key="menu"
              wellsOpened={wellsOpened}
              toggleWellsOpened={() => setWellsOpened(!wellsOpened)}
              collapsed={collapsed}
            />
          </SidebarTopContainer>
          <SidebarBottomContainer>
            <ThemeSwitcher collapsed={collapsed} />
            {Object.keys(localizationStore.locales).length > 1 && (
            <Select
              style={{ width: collapsed ? 64 : 140, marginBottom: 8 }}
              size="small"
              value={localizationStore.currentLocales[0]}
              onSelect={(locale: string) => localizationStore.changeLocales([locale])}
            >
              {localizationStore.locales['en-US'] && (
              <Select.Option key="en-US" value="en-US">
                {collapsed ? 'En' : 'English'}
              </Select.Option>
              )}
              {localizationStore.locales['ru-Ru'] && (
              <Select.Option key="ru-Ru" value="ru-Ru">
                {collapsed ? 'Ru' : 'Русский'}
              </Select.Option>
              )}
            </Select>
            )}
            <Select
              style={{ width: collapsed ? 64 : 140, marginBottom: 8 }}
              size="small"
              value={timeZonesStore.selected}
              onSelect={(time: number) => timeZonesStore.select(time)}
            >
              {timeZonesStore.list.map((time) => (
                <Select.Option key={time} value={time}>
                  {!collapsed && 'UTC '}
                  {time < 0 ? time : `+${time}`}
                </Select.Option>
              ))}
            </Select>
            <ScaleUnitSwitcher
              style={{ width: collapsed ? 64 : 140, marginBottom: 24 }}
              collapsed={collapsed}
            />
            {/* <SidebarBottomCollapsed onClick={() => setCollapsed(!collapsed)}>
              {collapsed
                ? <DoubleRightOutlined />
                : <DoubleLeftOutlined style={{ marginRight: 2 }} />}
              {!collapsed && l10n.getString('CollapseSidebar')}
            </SidebarBottomCollapsed> */}
          </SidebarBottomContainer>
          <InfoModal visible={infoVisible} onClose={() => setInfoVisible(false)} />
        </SidebarContainer>
        <TreePanelContainer visible={wellsOpened}>
          <WellsSearch />
          <FolderTree screensStore={screensStore} />
          <DataBlockContainer>
            {screensStore.tabletsStore && (
            <>
              {screensStore.tabletsStore.editingTablet
                && screensStore.tabletsStore.editingCurve && (
                <CurveParamsResolver
                  key={screensStore.tabletsStore.editingCurve.sourceDto.curveId}
                  curve={screensStore.tabletsStore.editingCurve}
                  tablet={screensStore.tabletsStore.editingTablet}
                />
              )}
              {screensStore.tabletsStore.editingTrack && (
                <TrackParams
                  key={screensStore.tabletsStore.editingTrack.innerId}
                  track={screensStore.tabletsStore.editingTrack}
                />
              )}
            </>
            )}
            {!screensStore.tabletsStore?.editingTrack
              && !screensStore.tabletsStore?.editingCurve && <FolderData />}
          </DataBlockContainer>
        </TreePanelContainer>
        <ScreensContainer ref={tabletsRef}>
          <ScreensTabs
            isFullscreen={isFullscreen}
            setFullscreen={setFullscreen}
            screensStore={screensStore}
          />
          {screensStore.currentScreen?.inProgress && (
          <TabletsWrapper>
            <TabletPreloader />
          </TabletsWrapper>
          )}
          <TabletsList screensStore={screensStore} />
        </ScreensContainer>
        <DragNode />
      </AppWrapper>
      {well != null && (
        <OpenWellModal screensStore={screensStore} visible wellId={well} />
      )}
    </ThemeProvider>
  );
});
