import './App.css';
import { observer } from 'mobx-react-lite';
import {
  Route, Routes,
} from 'react-router-dom';
import { LoginPage } from './pages/Login';
import { TabletsPage } from './pages/Tablets';

import { authStore } from './stores/auth';
import { RedirectAuth } from './pages/RedirectAuth';
import { LithologyBuilder } from './pages/LithologyBuilder';

const App = observer(() => (authStore.token ? (
  <Routes>
    <Route path="/Account/ExternalLogin" element={<RedirectAuth />} />
    <Route path="/Lithology" element={<LithologyBuilder />} />
    <Route index element={<TabletsPage />} />
  </Routes>
) : (
  <Routes>
    <Route path="/Account/ExternalLogin" element={<RedirectAuth />} />
    <Route path="/Lithology" element={<LithologyBuilder />} />
    <Route index element={<LoginPage />} />
  </Routes>
)));

export default App;
