import { Container } from '@inlet/react-pixi';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { TabletContext } from './TabletProvider';
import { ContainerMask } from './ContainerMask';

export const ScrollContainer = observer(({ children }) => {
  const { tablet } = useContext(TabletContext);
  const { height, scrollTop } = tablet.tabletScroll;

  let y = 0;
  if (tablet.params.orientation === 'horizontal') {
    y = -tablet.tracks.tracksWidth + height + scrollTop;
  }

  return (
    <ContainerMask
      width={tablet.params.canvasSize[0]}
      height={tablet.params.canvasSize[1]}
    >
      <Container x={0} y={y}>
        {children}
      </Container>
    </ContainerMask>
  );
});
